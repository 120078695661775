import {notify} from 'react-notify-toast';

let errorColor = { background: '#D80027', text: "#fff" };
let successColor = { background: '#0ECB81', text: "#fff" };
let warningColor = { background: '#F0B90B', text: "#fff" };
let infoColor = { background: '#2684FF', text: "#fff" };

class Toast {
    constructor(msg, type) {
        this.msg = msg;
        this.show = notify.createShowQueue();
    }
    toastError = () => this.show(this.msg, 'custom', 3000, errorColor);
    toastSuccess = () => this.show(this.msg, 'custom', 3000, successColor);
    toastInfo = () => this.show(this.msg, 'custom', 3000, infoColor);
    toastWarning = () => this.show(this.msg, 'custom', 3000, warningColor);
}

export const toast = {
    error:  (msg) => {
        return new Toast(msg).toastError()
    },
    success:  (msg) => {
        return new Toast(msg).toastSuccess()
    },
    info:  (msg) => {
        return new Toast(msg).toastInfo()
    },
    warn: (msg) => {
        return new Toast(msg).toastWarning()
    }
}