import React, {Suspense } from 'react';
import {Switch, Route} from 'react-router-dom';
import Loadable from 'react-loadable';
import Loader from './layout/Loader'
import Aux from "../hoc/_Aux";
import ScrollToTop from './layout/ScrollToTop';
import authRoutes from "../authRoutes";
import '../../node_modules/font-awesome/scss/font-awesome.scss';
import Page404 from "./layout/404";
import Notifications from 'react-notify-toast';

const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader
});

const App = () => {

        const authRouter = authRoutes.map((route, index) => {
          return (route.component) ? (
              <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => (
                      <route.component {...props} />
                  )} />
          ) : null;
        });

        return (
            <Aux>
                <ScrollToTop>
                    <Suspense fallback={<Loader/>}>
                        <Switch>
                            {authRouter}
                            <Route exact={true} path="/404" component={Page404} />
                            <Route path="/" component={AdminLayout} />
                        </Switch>
                    </Suspense>
                </ScrollToTop>
                <Notifications options={{zIndex: 9999}}/>
            </Aux>
        );
}

export default App;
